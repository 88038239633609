import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import { BarcodeForm } from "../common/BarcodeForm";
import SearchDrugAndForm from "../common/SearchDrugAndForm";
import { findProducts } from "../../api/products";

const AnswerSearchOptions = (props) => {
  const [key, setKey] = useState("form");

  const barcodeSubmissionHandler = async (barcode) => {
    try {
      const product = await findProducts(barcode);
      props.onSelect(product.drug.id, product.drugForm.id);
    } catch (error) {
      props.onError(error);
    }
  };

  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab eventKey="form" title="Drug &amp; Form">
        <SearchDrugAndForm onSelect={props.onSelect} onError={props.onError}>
          {props.children}
        </SearchDrugAndForm>
      </Tab>
      <Tab eventKey="barcode" title="Barcode">
        <BarcodeForm
          title={null}
          onSubmitHandler={barcodeSubmissionHandler}
          isLoading={props.isLoading}
        >
          {props.children}
        </BarcodeForm>
      </Tab>
    </Tabs>
  );
};

AnswerSearchOptions.propTypes = {
  onError: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default AnswerSearchOptions;
