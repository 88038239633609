import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import AuthContext from "../../store/auth-context";
import { reviewAnswer } from "../../api/answers";
import { dateToShortString } from "../../lib/dateUtilities";

function ReviewAnswersFormRow(props) {
  const { user } = useContext(AuthContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { answer } = props;

  const showModal = () => {
    setShowConfirmation(true);
  };

  const hideModal = () => {
    setShowConfirmation(false);
  };

  const markAsReviewed = async () => {
    setShowConfirmation(false);

    if (user.id === answer.updated_by_user.id) {
      console.log("Cannot mark as reviewed");
    }

    setIsSaving(true);

    try {
      const response = await reviewAnswer(answer.id);
      props.onSave(response);
    } catch (error) {
      props.onError(error.response.data.message);
    }

    setIsSaving(false);
  };

  const isReviewed = answer.is_reviewed;

  const answerText = isReviewed ? answer.answer : answer.updated_answer;
  const referenceText = isReviewed
    ? answer.reference
    : answer.updated_reference;

  const updatedBy = _.get(answer, ["updated_by_user", "id"], null);

  const currentUserIsAuthor = user.id === updatedBy;

  return (
    <>
      <Modal show={showConfirmation} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Review</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Do you want to mark this answer as reviewed?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={markAsReviewed}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label>
            <b>{answer.question.question}</b>
          </label>
          <div className="form-control" dangerouslySetInnerHTML={{__html: answerText}} style={{backgroundColor: "#e9ecef", minHeight: 182}} />
        </div>
        <div className="form-group col-md-4">
          <label>
            <b>Reference</b>
          </label>
          <textarea
            className="form-control"
            defaultValue={referenceText}
            readOnly={true}
            rows={7}
          />
        </div>
        <div className="form-group col-md-4">
          <label> </label>
          <div>
            <b>Updated by:</b> {_.get(answer, "updated_by_user.email", "")}
          </div>
          <div>
            <b>Updated at:</b>{" "}
            {dateToShortString(_.get(answer, "updated_at", ""))}
          </div>
          {answer.is_reviewed && (
            <>
              <div>
                <b>Reviewed by:</b>{" "}
                {_.get(answer, "reviewed_by_user.email", "")}
              </div>
              <div>
                <b>Reviewed at:</b>{" "}
                {dateToShortString(_.get(answer, "reviewed_at", ""))}
              </div>
            </>
          )}
          {!answer.is_reviewed && (
            <div>
              <button
                disabled={currentUserIsAuthor || isSaving}
                className="btn btn-primary mt-3"
                onClick={showModal}
              >
                Approve{" "}
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

ReviewAnswersFormRow.propTypes = {
  answer: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ReviewAnswersFormRow;
