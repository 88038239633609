import React, { useReducer, useState } from "react";

import PropTypes from "prop-types";
import Autocomplete from "react-autocomplete";
import { findProductsByDrugAndForm } from "../../api/products";

const INITIAL_STATE = {
  isLoading: false,
  showMenu: false,
  items: [],
};

const ACTIONS = {
  CLEAR: "CLEAR",
  SEARCHING: "SEARCHING",
  SEARCH_COMPLETE: "SEARCH_COMPLETE",
  ITEM_SELECTED: "ITEM_SELECTED",
  SHOW_MENU: "SHOW_MENU",
  HIDE_MENU: "HIDE_MENU",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SEARCHING:
      return {
        ...INITIAL_STATE,
        isLoading: true,
        showMenu: true,
        items: [{ drugAndDrugForm: "Loading..." }],
      };
    case ACTIONS.SEARCH_COMPLETE:
      return {
        ...INITIAL_STATE,
        isLoading: false,
        showMenu: true,
        items:
          action.items.length > 0
            ? action.items
            : [{ drugAndDrugForm: "No drug found!" }],
      };
    case ACTIONS.ITEM_SELECTED:
      return {
        ...INITIAL_STATE,
        isLoading: false,
        showMenu: false,
        items: state.items,
      };
    case ACTIONS.SHOW_MENU:
      return {
        ...state,
        showMenu: true,
      };
    case ACTIONS.HIDE_MENU:
      return {
        ...state,
        showMenu: false,
      };
    default:
      return INITIAL_STATE;
  }
};

const SearchDrugAndForms = (props) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [value, setValue] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const renderItem = (item, isHighlighted) => {
    return (
      <div
        key={item.drugAndDrugForm}
        style={{
          background: isHighlighted ? "rgb(235, 235, 235)" : "white",
          padding: "0.5rem 0.5rem",
        }}
      >
        {item.drugAndDrugForm}
      </div>
    );
  };

  const textChangeHandler = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (newValue.trim() === "") {
      dispatch({ type: ACTIONS.CLEAR });
      return;
    }

    setSearchTimeout(setTimeout(searchDrugAndForms.bind(null, newValue), 400));
  };

  const searchDrugAndForms = async (searchTerm) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
      setSearchTimeout(null);
    }

    dispatch({ type: ACTIONS.SEARCHING });

    try {
      const products = await findProductsByDrugAndForm(searchTerm);
      dispatch({ type: ACTIONS.SEARCH_COMPLETE, items: products });
    } catch (error) {
      props.onError(error);
    }
  };

  const selectionHandler = (text, item) => {
    setValue(item.drugAndDrugForm);
    dispatch({ type: ACTIONS.ITEM_SELECTED });
    props.onSelect(item.drug, item.drugForm);
  };

  const inputBlurHandler = () => {
    dispatch({ type: ACTIONS.HIDE_MENU });
  };

  const inputFocusHandler = () => {
    if (state.items.length > 0) {
      dispatch({ type: ACTIONS.SHOW_MENU });
    }
  };

  return (
    <div className="mt-4">
      <form className="form">
        <div className="form-row">
          <div className="form-group col-sm-12 col-md-6">
            <Autocomplete
              className="form-control"
              getItemValue={(item) => item.drugAndDrugForm}
              items={state.items}
              inputProps={{
                className: "form-control",
                placeholder: "Drug and Form",
                onFocus: inputFocusHandler,
                onBlur: inputBlurHandler,
              }}
              menuStyle={{
                zIndex: 1000,
                borderRadius: "3px",
                boxShadow: "rgb(0 0 0 / 10%) 0px 2px 12px",
                background: "rgba(255, 255, 255, 0.9)",
                padding: "2px 0",
                position: "absolute",
                fontSize: "90%",
                left: "0",
                top: "auto",
              }}
              wrapperProps={{
                style: {
                  display: "inline-block",
                  width: "calc(100% - 2rem)",
                  position: "relative",
                },
              }}
              renderItem={renderItem}
              value={value}
              onChange={textChangeHandler}
              onSelect={selectionHandler}
              open={state.showMenu}
              isItemSelectable={(item) => item.drug}
            />
            <div
              style={{
                display: "inline-block",
                width: "2rem",
                textAlign: "center",
              }}
            >
              {state.isLoading && (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </div>
          {props.children && (
            <div className="form-group col-md-6">{props.children}</div>
          )}
        </div>
      </form>
    </div>
  );
};

SearchDrugAndForms.propTypes = {
  onError: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SearchDrugAndForms;
