import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getRecentLogs = async () => {
  const url = `${API_BASE_URL}/audit-logs?_sort=created_at:desc`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

export const getLogDetail = async (id) => {
  const url = `${API_BASE_URL}/audit-logs/${id}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};
