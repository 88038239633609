import axios from "axios";
import { API_BASE_URL } from "../constants";

// get all the qeustions for the given barcode
export const findProducts = async (barcode) => {
  const url = `${API_BASE_URL}/products?barcode=${barcode}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  const products = response.data;
  if (products && products.length === 1) {
    return products[0];
  } else {
    if (products && products.length > 1) {
      throw new Error("More than 1 product found for the given barcode.");
    } else {
      throw new Error("No product found for the given barcode.");
    }
  }
};

export const findProductsByDrugAndForm = async (searchText) => {
  const url = `${API_BASE_URL}/products/search?drugAndDrugForm=${searchText}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};
