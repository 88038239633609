import React, { useCallback, useEffect, useReducer } from "react";
// import { getDrugForm } from "../../api/drugForms";
import Loader from "../UI/Loader";
import ScreenHeader from "../common/ScreenHeader";
import Alert from "../common/Alert";
import ReviewQuestionsSearchForm from "./ReviewQuestionsSearchForm";
import ReviewQuestionsForm from "./ReviewQuestionsForm";

import {
  findQuestionsByDrugForm,
  getQuestionsToBeReviewed,
} from "../../api/questions";

const INITIAL_STATE = {
  alert: null,
  isLoading: false,
  drugForm: null,
  questions: [],
};

const ACTIONS = {
  LOADING: "LOADING",
  SEARCH_COMPLETE: "SEARCH_COMPLETE",
  ALERT: "ALERT",
  DEFAULT: "DEFAULT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOADING:
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };
    case ACTIONS.SEARCH_COMPLETE:
      return {
        ...INITIAL_STATE,
        drugForm: action.drugForm,
        questions: action.questions,
      };
    case ACTIONS.ALERT:
      let newAction = action;
      delete newAction.type;
      return {
        ...state,
        ...newAction,
        isLoading: false,
      };
    default:
      return INITIAL_STATE;
  }
};

const ReviewQuestionsScreen = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const setAlertWithError = (error) => {
    let alert = {
      type: "error",
      message: "Something went wrong",
    };

    if (error.message) {
      alert = { type: "error", message: error.message };
    } else if (typeof error === "string") {
      alert = { type: "error", message: error };
    }

    dispatch({
      type: ACTIONS.ALERT,
      alert: alert,
    });
  };

  const searchResultHandler = async (drugForm) => {
    dispatch({ type: ACTIONS.LOADING });

    const drugFormId = drugForm.id;

    try {
      // const drugForm = await getDrugForm(drugFormId);
      const questions = await findQuestionsByDrugForm(drugFormId);

      dispatch({
        type: ACTIONS.SEARCH_COMPLETE,
        drugForm: drugForm,
        questions: questions,
      });
    } catch (error) {
      setAlertWithError(error);
    }
  };

  const loadQuestionsToBeReviewed = useCallback(async () => {
    dispatch({ type: ACTIONS.LOADING });

    try {
      const questions = await getQuestionsToBeReviewed();

      dispatch({
        type: ACTIONS.SEARCH_COMPLETE,
        drugForm: null,
        questions: questions,
      });
    } catch (error) {
      setAlertWithError(error);
    }
  }, []);

  useEffect(() => {
    loadQuestionsToBeReviewed();
  }, [loadQuestionsToBeReviewed]);

  const saveHandler = (updatedQuestion) => {
    if (state.drugForm) {
      const questions = state.questions;
      const updatedQuestions = questions.map((question) => {
        if (question.id === updatedQuestion.id) {
          return updatedQuestion;
        } else {
          return question;
        }
      });
      dispatch({
        type: ACTIONS.ALERT,
        alert: { type: "success", message: "Answer marked as reviewed!" },
        questions: updatedQuestions,
      });
    } else {
      loadQuestionsToBeReviewed();
    }
  };

  const errorHandler = (error) => {
    dispatch({
      type: ACTIONS.ALERT,
      alert: { type: "error", message: error },
    });
  };

  let selectionInfo = null;

  if (state.drugForm) {
    selectionInfo = (
      <span>
        <b>Selection:</b> {state.drugForm.name}
      </span>
    );
  }

  return (
    <>
      <ScreenHeader title="Review Questions" />
      <Alert alert={state.alert} />
      <ReviewQuestionsSearchForm
        onError={(error) => {
          console.log(error);
        }}
        onSelect={searchResultHandler}
      >
        {selectionInfo}
      </ReviewQuestionsSearchForm>
      {state.isLoading && <Loader height="200px" />}
      {!state.isLoading && state.questions && (
        <ReviewQuestionsForm
          questions={state.questions}
          onSave={saveHandler}
          onError={errorHandler}
        />
      )}
    </>
  );
};

export default ReviewQuestionsScreen;
