import React, { useState } from "react";

export const BarcodeForm = (props) => {
  const [enteredBarcode, setEnteredBarcode] = useState("");
  const [hasError, setHasError] = useState(false);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (enteredBarcode.trim().length === 0) {
      setHasError(true);
      return;
    }

    props.onSubmitHandler(enteredBarcode.trim());
  };

  const barcodeChangeHandler = (event) => {
    setHasError(false);
    setEnteredBarcode(event.target.value);
  };

  let button = (
    <button type="submit" className="btn btn-primary">
      Submit
    </button>
  );

  if (props.isLoading) {
    button = (
      <button type="submit" className="btn btn-primary">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Loading...</span>
      </button>
    );
  }

  return (
    <div className="mt-4">
      <form onSubmit={onSubmitHandler} className="form">
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-row">
              <div className="form-group col">
                <input
                  type="text"
                  className={`form-control w-100 ${
                    hasError ? "is-invalid" : ""
                  }`}
                  placeholder="Barcode"
                  onChange={barcodeChangeHandler}
                />
                <div className="invalid-feedback">
                  Please provide a valid barcode.
                </div>
              </div>
              <div className="form-group col-auto">{button}</div>
            </div>
          </div>
          {props.children && <div className="col-md-6">{props.children}</div>}
        </div>
      </form>
    </div>
  );
};
