import { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppNavbar from "./components/UI/AppNavbar";
import AnswersPage from "./pages/admin/AnswersPage";
import QuestionsPage from "./pages/admin/QuestionsPage";
import IndexPage from "./pages/IndexPage";
import AuthHandler from "./components/AuthHandler";
import LogoutHandler from "./components/LogoutHandler";
import AuthContext from "./store/auth-context";
// import Loader from "./components/UI/Loader";
import ReviewAnswersPage from "./pages/admin/ReviewAnswersPage";
import ReviewQuestionsPage from "./pages/admin/ReviewQuestionsPage";
import { API_BASE_URL, SITE_URL } from "./constants";
import AuditLogsPage from "./pages/admin/AuditLogsPage";
import AuditLogDetailPage from "./pages/admin/AuditLogDetailPage";
import { Helmet } from "react-helmet";

function App() {
  const { isAuthenticated } = useContext(AuthContext);

  const logoutUrl = `${API_BASE_URL}/logout?returnTo=${encodeURIComponent(
    SITE_URL + "/logout/callback"
  )}`;

  return (
    <>
      <Helmet>
        {process.env.REACT_APP_ADD_NEWRELIC === "true" ? (
          <script
            type="text/javascript"
            src={`${process.env.PUBLIC_URL}/scripts/newrelic.js`}
          />
        ) : null}
      </Helmet>
      <AppNavbar />
      <Switch>
        {!isAuthenticated && (
          <>
            <Route path="/" exact>
              <IndexPage />
            </Route>
            <Route path="/logout/callback">
              <Redirect to="/" />
            </Route>
            <Route path="/auth/:authProvider/callback">
              <AuthHandler />
            </Route>
            <Route path="/admin">
              <Redirect to="/" />
            </Route>
          </>
        )}
        {isAuthenticated && (
          <>
            <Route path="/" exact>
              <IndexPage />
            </Route>
            <Route path="/admin" exact>
              <Redirect to="/admin/answers" />
            </Route>
            <Route path="/admin/answers">
              <AnswersPage />
            </Route>
            <Route path="/admin/questions">
              <QuestionsPage />
            </Route>
            <Route path="/admin/review_answers">
              <ReviewAnswersPage />
            </Route>
            <Route path="/admin/review_questions">
              <ReviewQuestionsPage />
            </Route>
            <Route path="/admin/audit-logs/:id" exact>
              <AuditLogDetailPage />
            </Route>
            <Route path="/admin/audit-logs" exact>
              <AuditLogsPage />
            </Route>
            <Route path="/logout/callback" exact>
              <LogoutHandler />
            </Route>
            <Route
              path="/logout"
              component={() => {
                window.location.href = logoutUrl;
                return null;
              }}
              exact
            />
            <Route path="/auth">
              <Redirect to="/admin/answers" />
            </Route>
          </>
        )}
      </Switch>
    </>
  );
}

export default App;
