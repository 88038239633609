import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import styles from "./AnswersForm.module.css";
import { saveAnswers } from "../../api/answers";
import { dateToShortString } from "../../lib/dateUtilities";
import SCEditor from "../SCEditor";

const AnswersForm = (props) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { questions, answers, drug, drugForm } = props;
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      const answerDictionary = (answers || []).reduce((result, item) => {
        result[item.question.id] = item;
        return result;
      }, {});

      let newFormData = {};
      questions.forEach((question) => {
        const answerItem = answerDictionary[question.id];

        const answerData = {
          answer: "",
          reference: "",
          updated_at: _.get(answerItem, ["updated_at"], null),
          updated_by_user: _.get(answerItem, ["updated_by_user"], null),
          reviewed_by_user: _.get(answerItem, ["reviewed_by_user"], null),
          reviewed_at: _.get(answerItem, ["reviewed_at"], null),
          is_reviewed: _.get(answerItem, ["is_reviewed"], false),
        };

        if (answerItem) {
          answerData["answer"] = _.defaultTo(
            answerItem["updated_answer"],
            answerItem["answer"]
          );
          answerData["reference"] = _.defaultTo(
            answerItem["updated_reference"],
            answerItem["reference"]
          );
        }

        newFormData[question.id] = {
          ...answerData,
        };
      });

      setFormData(newFormData);
      setIsInitialized(true);
    }
  }, [questions, answers, isInitialized]);

  const formSubmissionHandler = async (event) => {
    event.preventDefault();

    const data = {
      drugId: drug.id,
      drugFormId: drugForm.id,
      answers: formData,
    };

    setIsLoading(true);
    try {
      let response = await saveAnswers(data);
      props.onSave(response);
    } catch (error) {
      props.onError("Something went wrong.");
    }
    setIsLoading(false);
  };

  const answerChangeHandler = (questionId, value) => {
    setFormData((prevState) => {
      let newState = { ...prevState };
      newState[questionId]["answer"] = value;
      return newState;
    });
  };

  const referenceChangeHandler = (questionId, event) => {
    setFormData((prevState) => {
      let newState = { ...prevState };
      newState[questionId]["reference"] = event.target.value;
      return newState;
    });
  };

  if (!isInitialized) {
    return null;
  }

  return (
    <div className={[props.className, styles.form].join(" ")}>
      <form className="form form-striped" onSubmit={formSubmissionHandler}>
        <div className="form-group">
          <div className={[styles.title, "h5"].join(" ")}>Questions:</div>
        </div>
        {formData &&
          questions.map((item) => {
            const isReviewd = _.get(formData, `${item.id}.is_reviewed`, false);

            return (
              <div className="form-row mt-2" key={item.id}>
                <div className="form-group col-md-4">
                  <label>
                    <b>{item.question}</b>
                  </label>
                  <SCEditor 
                    defaultValue={
                      formData[item.id] && formData[item.id]["answer"]
                    }
                    onChange={answerChangeHandler.bind(null, item.id)}
                  />
                  {/* <textarea
                    className="form-control"
                    defaultValue={
                      formData[item.id] && formData[item.id]["answer"]
                    }
                    onChange={answerChangeHandler.bind(null, item.id)}
                    rows={7}
                  /> */}
                </div>
                <div className="form-group col-md-4">
                  <label>
                    <b>Reference</b>
                  </label>
                  <textarea
                    className="form-control"
                    defaultValue={
                      formData[item.id] && formData[item.id]["reference"]
                    }
                    onChange={referenceChangeHandler.bind(null, item.id)}
                    rows={7}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label> </label>
                  {/* <div>
                    <b>Last updated by:</b>{" "}
                    {_.get(formData, `${item.id}.updated_by_user.email`, "")}
                  </div>
                  <div>
                    <b>Last update at:</b>{" "}
                    {_.get(formData, `${item.id}.updated_at`, "")}
                  </div> */}
                  {isReviewd && (
                    <>
                      <div>
                        <b>Reviewed by:</b>{" "}
                        {_.get(
                          formData,
                          `${item.id}.reviewed_by_user.email`,
                          ""
                        )}
                      </div>
                      <div>
                        <b>Reviewed at:</b>{" "}
                        {dateToShortString(
                          _.get(formData, `${item.id}.reviewed_at`, "")
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        <div className="sticky-action-bar row">
          <div className="col py-3">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

AnswersForm.propTypes = {
  drug: PropTypes.object.isRequired,
  drugForm: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AnswersForm;
