import React from "react";
import PropTypes from "prop-types";
import ReviewQuestionsFormRow from "./ReviewQuestionsFormRow";

import classes from "./ReviewQuestionsForm.module.css";

const ReviewQuestionsForm = (props) => {
  const { questions } = props;

  const groups = {};

  questions.forEach((element) => {
    const key = `${element.drugForm.name}`;
    if (groups[key]) {
      groups[key].push(element);
    } else {
      groups[key] = [element];
    }
  });

  const groupKeys = Object.keys(groups);

  return (
    <div className="form form-striped">
      <div className="form-group">
        <div className={["h5"].join(" ")}>Questions:</div>
      </div>
      {groupKeys.map((groupKey) => {
        const groupQuestions = groups[groupKey];
        return (
          <div className={classes.questionGroup} key={groupKey}>
            <h3 className={classes.questionGroupTitle}>{groupKey}</h3>
            {groupQuestions.map((question) => {
              return (
                <ReviewQuestionsFormRow
                  key={question.id}
                  question={question}
                  onSave={props.onSave}
                  onError={props.onError}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

ReviewQuestionsForm.propTypes = {
  questions: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ReviewQuestionsForm;
