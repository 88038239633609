import React, { useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import ScreenHeader from "../common/ScreenHeader";
import { getLogDetail } from "../../api/auditLogs";
import Loader from "../UI/Loader";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const INITIAL_STATE = {
  alert: null,
  isLoading: false,
  auditLog: null,
};

const ACTIONS = {
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.START_LOADING:
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };
    case ACTIONS.END_LOADING:
      return {
        ...INITIAL_STATE,
        isLoading: false,
        auditLog: action.auditLog,
      };
    default:
      return INITIAL_STATE;
  }
};

const AuditLogDetailScreen = (props) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { id: auditLogId } = props;

  const loadLogDetail = useCallback(async () => {
    dispatch({ type: ACTIONS.START_LOADING });

    try {
      const auditLog = await getLogDetail(auditLogId);
      dispatch({ type: ACTIONS.END_LOADING, auditLog: auditLog });
    } catch (error) {
      dispatch({ type: ACTIONS.END_LOADING, auditLog: null });
    }
  }, [auditLogId]);

  useEffect(() => {
    loadLogDetail();
  }, [loadLogDetail]);

  let content = <div className="text-center">Data could not be laoded</div>;

  if (state.auditLog) {
    content = (
      <div className="form">
        <div className="form-row">
          <div className="form-group col-md-6">
            <label for="inputContentType">Content Type</label>
            <input
              type="text"
              className="form-control"
              id="inputContentType"
              defaultValue={state.auditLog.contentType}
              disabled
            />
          </div>
          <div className="form-group col-md-6">
            <label for="inputAction">Action</label>
            <input
              type="text"
              className="form-control"
              id="inputAction"
              defaultValue={state.auditLog.action}
              disabled
            />
          </div>
        </div>
        <div className="form-group">
          <label>Author</label>
          <SyntaxHighlighter language="json" style={docco}>
            {JSON.stringify(state.auditLog.author, null, 2)}
          </SyntaxHighlighter>
        </div>
        <div className="form-group">
          <label>Content</label>
          <SyntaxHighlighter language="json" style={docco}>
            {JSON.stringify(state.auditLog.content, null, 2)}
          </SyntaxHighlighter>
        </div>
      </div>
    );
  }

  return (
    <>
      <ScreenHeader title={`Audit Log: ${props.id}`} showBackButton />
      {state.isLoading && <Loader height="200px" />}
      {!state.isLoading && content}
    </>
  );
};

AuditLogDetailScreen.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AuditLogDetailScreen;
