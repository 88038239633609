import React from "react";
import { useParams } from "react-router-dom";
import AuditLogDetailScreen from "../../components/auditLogs/AuditLogDetailScreen";
import Layout from "../../layouts/Layout";

const AuditLogDetailPage = () => {
  const { id } = useParams();
  return (
    <Layout>
      <AuditLogDetailScreen id={id} />
    </Layout>
  );
};

export default AuditLogDetailPage;
