import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import AuthContext from "../store/auth-context";
import Loader from "./UI/Loader";

const LogoutHandler = () => {
  const {
    isAuthenticated,
    isLoading,
    logout: logoutHandler,
  } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      logoutHandler();
    }
  }, [isAuthenticated, isLoading, logoutHandler]);

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <Loader />;
};

export default LogoutHandler;
