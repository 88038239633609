import axios from "axios";
import { createContext, useCallback, useState } from "react";
import { API_BASE_URL } from "../constants";

const LOCAL_STORAGE_KEY = "_script_cards_auth_state";

const AuthContext = createContext({
  isAuthenticated: false,
  isLoading: false,
  user: null,
  login: (state) => {},
  logout: () => {},
});

const saveState = (state) => {
  const newState = {
    isAuthenticated: state.isAuthenticated,
    user: state.user,
  };
  const stateString = JSON.stringify(newState);
  localStorage.setItem(LOCAL_STORAGE_KEY, stateString);
};

const retrieveStoredState = () => {
  const storedStateJSON = localStorage.getItem(LOCAL_STORAGE_KEY);
  const state = JSON.parse(storedStateJSON);
  if (state) {
    return {
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      isLoading: false,
    };
  }
  return { isAuthenticated: false, isLoading: false, user: null };
};

export const AuthContextProvider = (props) => {
  const storedState = retrieveStoredState();
  const [state, setState] = useState(storedState);

  const loginHandler = (state) => {
    saveState(state);
    setState(state);
  };

  const logoutHandler = useCallback(async () => {
    setState((prevState) => {
      return {
        isAuthenticated: prevState.isAuthenticated,
        isLoading: false,
        user: prevState.user,
      };
    });

    try {
      await axios.get(API_BASE_URL + "/logout/callback", {
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    }

    const newState = { isAuthenticated: false, isLoading: false, user: null };
    saveState(newState);
    setState(newState);
  }, []);

  const contextValue = {
    isAuthenticated: state.isAuthenticated,
    user: state.user,
    isLoading: state.isLoading,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
