import React from "react";
import ReviewAnswersScreen from "../../components/reviewAnswers/ReviewAnswersScreen";
import Layout from "../../layouts/Layout";

function ReviewAnswersPage() {
  return (
    <Layout>
      <ReviewAnswersScreen />
    </Layout>
  );
}

export default ReviewAnswersPage;
