import axios from "axios";
import { API_BASE_URL } from "../constants";

// get all the qeustions for the given barcode
export const findAnswers = async (barcode) => {
  const url = `${API_BASE_URL}/answers?drugForm.products.barcode=${barcode}&drug.products.barcode=${barcode}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

export const findAnswersByDrugAndForm = async (drugId, drugFormId) => {
  const url = `${API_BASE_URL}/answers?drugForm=${drugFormId}&drug=${drugId}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

export const getAnswersToBeReviewed = async () => {
  const url = `${API_BASE_URL}/answers?is_reviewed=0&_sort=updated_at:desc`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

export const saveAnswers = async (data) => {
  const url = `${API_BASE_URL}/answers/save`;
  const response = await axios.post(url, data, {
    withCredentials: true,
  });
  return response.data;
};

export const reviewAnswer = async (id) => {
  const url = `${API_BASE_URL}/answers/review/${id}`;
  const response = await axios.put(url, null, {
    withCredentials: true,
  });
  return response.data;
};
