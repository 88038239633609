import React from "react";

const Loader = (props) => {
  let classes = ["d-flex", "align-items-center ", "justify-content-center"];
  let styles = {};

  if (props.height) {
    styles = {
      height: props.height,
    };
  } else {
    classes.push("vh-100");
  }

  return (
    <div className={classes.join(" ")} style={styles}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
