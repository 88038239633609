import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { API_BASE_URL } from "../constants";
import AuthContext from "../store/auth-context";
import Loader from "./UI/Loader";

const AuthHandler = () => {
  const authContext = useContext(AuthContext);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    axios({
      method: "GET",
      url: `${API_BASE_URL}/auth/auth0/callback${location.search}`,
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.user) {
          authContext.login({
            isAuthenticated: true,
            user: data.user,
          });
        } else {
          setError("Something went wrong. Please contact support.");
        }
      })
      .catch((error) => {
        setError(
          "Something went wrong. Please check if the email is not already taken."
        );
        setIsDone(true);
      });
  }, [location, authContext]);

  if (!isDone) {
    return <Loader />;
  }

  if (isDone && error) {
    return (
      <div className="container-fluid mt-3">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  }

  return <Redirect to="/admin" />;
};

export default AuthHandler;
