import React, { useContext } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";

import brandLogo from "../../assets/ScriptCardsLogoWhite.png";
import { API_BASE_URL } from "../../constants";
import AuthContext from "../../store/auth-context";

const AppNavbar = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const loginUrl = API_BASE_URL + "/connect/auth0";

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Link className="navbar-brand" to="/">
        <img src={brandLogo} alt="ScriptCards Logo" height={30} />
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {isAuthenticated && (
            <Nav.Item>
              <NavLink className="nav-link" to="/admin/answers">
                Answers
              </NavLink>
            </Nav.Item>
          )}
          {isAuthenticated && (
            <Nav.Item>
              <NavLink className="nav-link" to="/admin/questions">
                Questions
              </NavLink>
            </Nav.Item>
          )}
          {isAuthenticated && (
            <Nav.Item>
              <NavLink className="nav-link" to="/admin/review_answers">
                Review Answers
              </NavLink>
            </Nav.Item>
          )}
          {isAuthenticated && (
            <Nav.Item>
              <NavLink className="nav-link" to="/admin/review_questions">
                Review Questions
              </NavLink>
            </Nav.Item>
          )}
          {isAuthenticated && (
            <Nav.Item>
              <NavLink className="nav-link" to="/admin/audit-logs">
                Audit Logs
              </NavLink>
            </Nav.Item>
          )}
          {isAuthenticated && (
            <NavDropdown title={user.email} id="nav-dropdown" alignRight>
              <NavLink className="dropdown-item" to="/logout">
                Logout
              </NavLink>
            </NavDropdown>
          )}
          {!isAuthenticated && (
            <Nav.Item>
              <Nav.Link href={loginUrl}>Login</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
