import axios from "axios";
import { API_BASE_URL } from "../constants";

// get all the qeustions for the given barcode
export const findQuestions = async (barcode) => {
  const url = `${API_BASE_URL}/questions?drugForm.products.barcode=${barcode}&_sort=id`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

export const findQuestionsByDrugForm = async (drugFormId) => {
  const url = `${API_BASE_URL}/questions?drugForm=${drugFormId}&_sort=id`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

export const getQuestionsToBeReviewed = async () => {
  const url = `${API_BASE_URL}/questions?is_reviewed=0&_sort=updated_at:desc`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

// // add a new question
// export const addQuestion = async (data) => {
//   const url = `${API_BASE_URL}/questions`;
//   return axios.post(url, data, {
//     withCredentials: true,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
// };

// // update an existing question
// export const updateQuestion = async (id, data) => {
//   const url = `${API_BASE_URL}/questions/${id}`;
//   return axios.put(url, data, {
//     withCredentials: true,
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
// };

// save the question (create + update) and set to be reviewed
export const saveQuestion = async (data) => {
  const url = `${API_BASE_URL}/questions/save`;
  const response = await axios.post(url, data, {
    withCredentials: true,
  });
  return response.data;
};

// delete an existing question
export const deleteQuestion = async (id) => {};

// save the reviewed questions
export const reviewQuestion = async (id) => {
  const url = `${API_BASE_URL}/questions/review/${id}`;
  const response = await axios.put(url, null, {
    withCredentials: true,
  });
  return response.data;
};
