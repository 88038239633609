import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { saveQuestion } from "../../api/questions";

const QuestionModal = (props) => {
  let questionInitialValue = props.question ? props.question.question : "";
  let weightInitialValue = props.question ? props.question.weight : 0;

  if (props.question && !props.question.is_reviewed) {
    questionInitialValue = props.question
      ? props.question.updated_question
      : "";
    weightInitialValue = props.question ? props.question.updated_weight : 0;
  }

  const [enteredQuestion, setEnteredQuestion] = useState(questionInitialValue);
  const [enteredWeight, setEnteredWeight] = useState(weightInitialValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEnteredQuestion(questionInitialValue);
    setEnteredWeight(weightInitialValue);
  }, [questionInitialValue, weightInitialValue]);

  const formSubmissionHandler = (event) => {
    event.preventDefault();

    setIsLoading(true);

    let promise;

    if (props.question && props.question.id) {
      promise = saveQuestion({
        id: props.question.id,
        question: enteredQuestion,
        weight: parseInt(enteredWeight),
        drugForm: props.drugForm,
      });
    } else {
      promise = saveQuestion({
        question: enteredQuestion,
        weight: parseInt(enteredWeight),
        drugForm: props.drugForm,
      });
    }

    promise
      .then((response) => {
        props.onSave(response);
      })
      .catch((error) => {
        props.onError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setEnteredQuestion("");
        setEnteredWeight("");
      });
  };

  const questionChangeHandler = (event) => {
    setEnteredQuestion(event.target.value);
  };

  const weightChangeHandler = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length > 0) {
      setEnteredWeight(parseInt(value) ?? 0);
    } else {
      setEnteredWeight(0);
    }
  };

  return (
    <Modal show={props.show} onHide={props.onDismiss}>
      <form onSubmit={formSubmissionHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Question"
              value={enteredQuestion}
              onChange={questionChangeHandler}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              placeholder="Weight"
              value={enteredWeight}
              onChange={weightChangeHandler}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={props.onDismiss}
            disabled={isLoading}
          >
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            Save changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

QuestionModal.propTypes = {
  drugForm: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  question: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
};

export default QuestionModal;
