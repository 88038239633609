import React from "react";
import Layout from "../../layouts/Layout";
import QuestionScreen from "../../components/questions/QuestionScreen";

const QuestionsPage = (props) => {
  return (
    <Layout>
      <QuestionScreen />
    </Layout>
  );
};

export default QuestionsPage;
