import React, { useReducer } from "react";
import { findQuestionsByDrugForm } from "../../api/questions";
import { findAnswersByDrugAndForm } from "../../api/answers";
import AnswersForm from "./AnswersForm";
import AnswerSearchOptions from "./AnswerSearchOptions";
import { getDrug } from "../../api/drugs";
import { getDrugForm } from "../../api/drugForms";
import Loader from "../UI/Loader";
import ScreenHeader from "../common/ScreenHeader";
import Alert from "../common/Alert";

const INITIAL_STATE = {
  alert: null,
  isLoading: false,
  drugForm: null,
  drug: null,
  questions: [],
  answers: [],
};

const ACTIONS = {
  LOADING: "LOADING",
  SEARCH_COMPLETE: "SEARCH_COMPLETE",
  ALERT: "ALERT",
  DEFAULT: "DEFAULT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOADING:
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };
    case ACTIONS.SEARCH_COMPLETE:
      return {
        ...INITIAL_STATE,
        drug: action.drug,
        drugForm: action.drugForm,
        questions: action.questions,
        answers: action.answers,
      };
    case ACTIONS.ALERT:
      let newAction = action;
      delete newAction.type;
      return {
        ...state,
        ...newAction,
        isLoading: false,
      };
    default:
      return INITIAL_STATE;
  }
};

const AnswerScreen = (props) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const answerSaveHandler = (answers) => {
    dispatch({
      type: ACTIONS.ALERT,
      alert: { type: "success", message: "Answers saved!" },
      answers: answers,
    });
  };

  const answerErrorHandler = (error) => {
    dispatch({
      type: ACTIONS.ALERT,
      alert: { type: "error", message: error },
    });
  };

  const answerSearchResultHandler = async (drugId, drugFormId) => {
    dispatch({ type: ACTIONS.LOADING });

    try {
      const drug = await getDrug(drugId);
      const drugForm = await getDrugForm(drugFormId);
      const questions = await findQuestionsByDrugForm(drugFormId);
      const answers = await findAnswersByDrugAndForm(drugId, drugFormId);

      dispatch({
        type: ACTIONS.SEARCH_COMPLETE,
        drug: drug,
        drugForm: drugForm,
        questions: questions,
        answers: answers,
      });
    } catch (error) {
      let alert = {
        type: "error",
        message: "Something went wrong",
      };
      if (error.message) {
        alert = { type: "error", message: error.message };
      } else if (typeof error === "string") {
        alert = { type: "error", message: error };
      }

      dispatch({
        type: ACTIONS.ALERT,
        alert: alert,
      });
    }
  };

  let selectionInfo = null;

  if (state.drug && state.drugForm) {
    selectionInfo = (
      <span>
        <b>Selection:</b> {state.drug.name} {state.drugForm.name}
      </span>
    );
  }

  return (
    <>
      <ScreenHeader title="Answers" />
      <Alert alert={state.alert} />
      <AnswerSearchOptions
        onError={(error) => {
          console.log(error);
        }}
        onSelect={answerSearchResultHandler}
      >
        {selectionInfo}
      </AnswerSearchOptions>
      {state.isLoading && <Loader height="200px" />}
      {!state.isLoading && state.drug && state.drugForm && (
        <>
          <AnswersForm
            className="mt-4"
            answers={state.answers}
            questions={state.questions}
            drug={state.drug}
            drugForm={state.drugForm}
            onSave={answerSaveHandler}
            onError={answerErrorHandler}
          />
        </>
      )}
    </>
  );
};

export default AnswerScreen;
