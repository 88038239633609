import React from "react";
import AuditLogScreen from "../../components/auditLogs/AuditLogScreen";
import Layout from "../../layouts/Layout";

const AuditLogsPage = () => {
  return (
    <Layout>
      <AuditLogScreen />
    </Layout>
  );
};

export default AuditLogsPage;
