import React from "react";
import Layout from "../../layouts/Layout";
import AnswerScreen from "../../components/answers/AnswerScreen";

const AnswersPage = (props) => {
  return (
    <Layout>
      <AnswerScreen />
    </Layout>
  );
};

export default AnswersPage;
