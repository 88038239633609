import React, { useCallback, useEffect, useReducer } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getRecentLogs } from "../../api/auditLogs";
import Alert from "../common/Alert";
import ScreenHeader from "../common/ScreenHeader";
import Loader from "../UI/Loader";

import classes from "./AuditLogScreen.module.css";

const INITIAL_STATE = {
  alert: null,
  isLoading: false,
  logs: [],
};

const ACTIONS = {
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.START_LOADING:
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };
    case ACTIONS.END_LOADING:
      return {
        ...INITIAL_STATE,
        isLoading: false,
        logs: action.logs,
      };
    default:
      return INITIAL_STATE;
  }
};

const AuditLogScreen = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const match = useRouteMatch();
  const history = useHistory();

  const loadRecentLogs = useCallback(async () => {
    dispatch({ type: ACTIONS.START_LOADING });

    try {
      const logs = await getRecentLogs();
      dispatch({ type: ACTIONS.END_LOADING, logs: logs });
    } catch (error) {
      dispatch({ type: ACTIONS.END_LOADING, logs: [] });
    }
  }, []);

  useEffect(() => {
    loadRecentLogs();
  }, [loadRecentLogs]);

  // events
  const handleRowClick = (id) => {
    const targetUrl = `${match.url}/${id}`;
    history.push(targetUrl);
  };

  const tableHead = (
    <thead>
      <tr>
        <th>ID</th>
        <th>Content Type</th>
        <th>Action</th>
      </tr>
    </thead>
  );

  const tableRows = [];

  if (state.logs) {
    state.logs.forEach((log) => {
      tableRows.push(
        <tr
          key={log.id}
          className={classes.resultRow}
          onClick={handleRowClick.bind(null, log.id)}
        >
          <td>{log.id}</td>
          <td>{log.contentType}</td>
          <td>{log.action}</td>
        </tr>
      );
    });
  } else {
    tableRows.push(
      <tr key="no-item-found">
        <td className="text-center" colSpan="3">
          No logs found
        </td>
      </tr>
    );
  }

  let tableBody = <tbody>{tableRows}</tbody>;

  const tableClasses = "table table-bordered table-striped table-hover";

  const content = (
    <table className={tableClasses}>
      {tableHead}
      {tableBody}
    </table>
  );

  return (
    <>
      <ScreenHeader title="Audit Logs" />
      <Alert alert={state.alert} />
      {state.isLoading && <Loader height="200px" />}
      {!state.isLoading && content}
    </>
  );
};

export default AuditLogScreen;
