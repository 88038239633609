import React from "react";
import ReviewQuestionsScreen from "../../components/reviewQuestions/ReviewQuestionsScreen";
import Layout from "../../layouts/Layout";

const ReviewQuestionsPage = () => {
  return (
    <Layout>
      <ReviewQuestionsScreen />
    </Layout>
  );
};

export default ReviewQuestionsPage;
