import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class SCEditor extends Component {
  constructor(props) {
    super(props)
    const defaultContent = this.props.defaultValue || "<p></p>"
    const blockContent = htmlToDraft(defaultContent)
    this.state = {
      editorState: EditorState.createWithContent(ContentState.createFromBlockArray(blockContent)),
      htmlValue: defaultContent
    }
  }

  onEditorStateChange = (editorState) => {
    const htmlValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    this.setState({
      editorState,
      htmlValue
    });
    if (this.props.onChange) {
      this.props.onChange(htmlValue);
    }
  };

  render() {
    const { editorState } = this.state;

    return (
      <div>
        <Editor
          toolbar={{ options: ['inline', 'list', 'history'] }}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          wrapperStyle={{backgroundColor: "white"}} 
          editorStyle={{padding: "0 5px 5px 6px", border: "1px solid #F1F1F1", borderRadius: "2px", height: 145}} 
        />
      </div>
    );
  }
}

export default SCEditor