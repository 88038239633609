export const dateToShortString = (dateString) => {
  const date = new Date(dateString);
  const formattedString = date.toLocaleString();

  if (formattedString) {
    return formattedString;
  }

  return "";
};
