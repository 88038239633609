import React, { useCallback, useEffect, useReducer } from "react";
import ReviewsSearchForm from "./ReviewAnswersSearchForm";
import { getDrug } from "../../api/drugs";
import { getDrugForm } from "../../api/drugForms";
import {
  findAnswersByDrugAndForm,
  getAnswersToBeReviewed,
} from "../../api/answers";
import Loader from "../UI/Loader";
import ReviewAnswersForm from "./ReviewAnswersForm";
import ScreenHeader from "../common/ScreenHeader";
import Alert from "../common/Alert";

const INITIAL_STATE = {
  alert: null,
  isLoading: false,
  drugForm: null,
  drug: null,
  answers: [],
};

const ACTIONS = {
  LOADING: "LOADING",
  SEARCH_COMPLETE: "SEARCH_COMPLETE",
  ALERT: "ALERT",
  DEFAULT: "DEFAULT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOADING:
      return {
        ...INITIAL_STATE,
        isLoading: true,
      };
    case ACTIONS.SEARCH_COMPLETE:
      return {
        ...INITIAL_STATE,
        drug: action.drug,
        drugForm: action.drugForm,
        answers: action.answers,
      };
    case ACTIONS.ALERT:
      let newAction = action;
      delete newAction.type;
      return {
        ...state,
        ...newAction,
        isLoading: false,
      };
    default:
      return INITIAL_STATE;
  }
};

function ReviewAnswersScreen() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const setAlertWithError = (error) => {
    let alert = {
      type: "error",
      message: "Something went wrong",
    };

    if (error.message) {
      alert = { type: "error", message: error.message };
    } else if (typeof error === "string") {
      alert = { type: "error", message: error };
    }

    dispatch({
      type: ACTIONS.ALERT,
      alert: alert,
    });
  };

  const searchResultHandler = async (drugId, drugFormId) => {
    dispatch({ type: ACTIONS.LOADING });

    try {
      const drug = await getDrug(drugId);
      const drugForm = await getDrugForm(drugFormId);
      const answers = await findAnswersByDrugAndForm(drugId, drugFormId);

      dispatch({
        type: ACTIONS.SEARCH_COMPLETE,
        drug: drug,
        drugForm: drugForm,
        answers: answers,
      });
    } catch (error) {
      setAlertWithError(error);
    }
  };

  const loadAnswersToBeReviewed = useCallback(async () => {
    dispatch({ type: ACTIONS.LOADING });

    try {
      const answers = await getAnswersToBeReviewed();

      dispatch({
        type: ACTIONS.SEARCH_COMPLETE,
        drug: null,
        drugForm: null,
        answers: answers,
      });
    } catch (error) {
      setAlertWithError(error);
    }
  }, []);

  useEffect(() => {
    loadAnswersToBeReviewed();
  }, [loadAnswersToBeReviewed]);

  const saveHandler = (updatedAnswer) => {
    if (state.drug && state.drugForm) {
      const answers = state.answers;
      const updatedAnswers = answers.map((answer) => {
        if (answer.id === updatedAnswer.id) {
          return updatedAnswer;
        } else {
          return answer;
        }
      });
      dispatch({
        type: ACTIONS.ALERT,
        alert: { type: "success", message: "Answer marked as reviewed!" },
        answers: updatedAnswers,
      });
    } else {
      loadAnswersToBeReviewed();
    }
  };

  const errorHandler = (error) => {
    dispatch({
      type: ACTIONS.ALERT,
      alert: { type: "error", message: error },
    });
  };

  let selectionInfo = null;

  if (state.drug && state.drugForm) {
    selectionInfo = (
      <span>
        <b>Selection:</b> {state.drug.name} {state.drugForm.name}
      </span>
    );
  }

  return (
    <>
      <ScreenHeader title="Reviews" />
      <Alert alert={state.alert} />
      <ReviewsSearchForm
        onError={(error) => {
          console.log(error);
        }}
        onSelect={searchResultHandler}
      >
        {selectionInfo}
      </ReviewsSearchForm>
      {state.isLoading && <Loader height="200px" />}
      {!state.isLoading && state.answers && (
        <ReviewAnswersForm
          answers={state.answers}
          onSave={saveHandler}
          onError={errorHandler}
        />
      )}
    </>
  );
}

export default ReviewAnswersScreen;
