import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getDrugForm = async (id) => {
  const url = `${API_BASE_URL}/drug-forms/${id}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

export const getDrugForms = async (barcode) => {
  const url = `${API_BASE_URL}/drug-forms?products.barcode=${barcode}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};

export const getDrugFormsByName = async (searchTerm) => {
  const url = `${API_BASE_URL}/drug-forms?name_contains=${searchTerm}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};
