import React from "react";
import PropTypes from "prop-types";

import { BsPencil } from "react-icons/bs";

import styles from "./SearchResult.module.css";

const SearchResult = (props) => {
  const { items } = props;

  const editClickHandler = (id) => {
    props.onEdit(id);
  };

  const listHeader = items && (
    <div className="row mt-sm-4 mb-sm-2 align-items-center">
      <div className="col">Total: {items.length}</div>
      <div className="col text-right">
        <button className="btn btn-primary" onClick={props.onAddClick}>
          Add
        </button>
      </div>
    </div>
  );

  const dataTable = items && (
    <table
      className={`table table-bordered table-striped table-hover ${styles.questions}`}
    >
      <thead>
        <tr>
          <th>ID</th>
          <th>Question</th>
          <th>Weight</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.length === 0 ? (
          <tr>
            <td colSpan={4} className="text-center">
              No Questions Found
            </td>
          </tr>
        ) : (
          items.map((item) => {
            const isReviewed = !(item.is_reviewed === false);
            const questionText = isReviewed
              ? item.question
              : item.updated_question;
            const weightText = isReviewed ? item.weight : item.updated_weight;
            return (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{questionText}</td>
                <td>{weightText}</td>
                <td className="py-0 align-middle">
                  <button
                    className="btn btn-link"
                    onClick={editClickHandler.bind(null, item.id)}
                  >
                    <BsPencil />
                  </button>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );

  return (
    <div className={styles.searchResult}>
      {listHeader}
      {dataTable}
    </div>
  );
};

SearchResult.propTypes = {
  drugForm: PropTypes.object,
  items: PropTypes.array,
  onAddClick: PropTypes.func,
};

export default SearchResult;
