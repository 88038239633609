import React from "react";
import PropTypes from "prop-types";
import ReviewAnswersFormRow from "./ReviewAnswersFormRow";

import classes from "./ReviewAnswersForm.module.css";

function ReviewAnswersForm(props) {
  const { answers } = props;

  const groups = {};

  answers.forEach((element) => {
    const key = `${element.drug.name} ${element.drugForm.name}`;
    if (groups[key]) {
      groups[key].push(element);
    } else {
      groups[key] = [element];
    }
  });

  const groupKeys = Object.keys(groups);

  return (
    <div className="form form-striped">
      <div className="form-group">
        <div className={["h5"].join(" ")}>Answers:</div>
      </div>
      {groupKeys.map((groupKey) => {
        const groupAnswers = groups[groupKey];
        return (
          <div className={classes.answerGroup} key={groupKey}>
            <h3 className={classes.answerGroupTitle}>{groupKey}</h3>
            {groupAnswers.map((answer) => {
              return (
                <ReviewAnswersFormRow
                  key={answer.id}
                  answer={answer}
                  onSave={props.onSave}
                  onError={props.onError}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

ReviewAnswersForm.propTypes = {
  answers: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ReviewAnswersForm;
