import React from "react";
import PropTypes from "prop-types";

const Alert = (props) => {
  const { alert } = props;

  if (!alert) return null;

  let alertClass = "alert-danger";
  if (alert.type === "success") {
    alertClass = "alert-success";
  }

  return (
    <div className={`alert ${alertClass}`} role="alert">
      {alert.message}
    </div>
  );
};

Alert.propTypes = {
  alert: PropTypes.object,
};

export default Alert;
