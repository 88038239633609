import React from "react";
import Layout from "../layouts/Layout";

const IndexPage = () => {
  return (
    <Layout>
      <div className="text-center">
        <h1 className="mt-5 font-weight-bold">Welcome to ScriptCards</h1>
      </div>
    </Layout>
  );
};

export default IndexPage;
