import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs } from "react-bootstrap";
import { BarcodeForm } from "../common/BarcodeForm";
import SearchDrugForm from "../common/SearchDrugForm";
import { getDrugForms } from "../../api/drugForms";

const QuestionSearchOptions = (props) => {
  const [key, setKey] = useState("form");

  const barcodeSubmissionHandler = async (barcode) => {
    try {
      const drugForms = await getDrugForms(barcode);
      if (drugForms.length === 0) {
        throw new Error("No drug found for this barcode");
      }
      props.onSelect(drugForms[0]);
    } catch (error) {
      props.onError(error);
    }
  };

  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab eventKey="form" title="Drug Form">
        <SearchDrugForm onSelect={props.onSelect} onError={props.onError}>
          {props.children}
        </SearchDrugForm>
      </Tab>
      <Tab eventKey="barcode" title="Barcode">
        <BarcodeForm
          onSubmitHandler={barcodeSubmissionHandler}
          isLoading={props.isLoading}
        >
          {props.children}
        </BarcodeForm>
      </Tab>
    </Tabs>
  );
};

QuestionSearchOptions.propTypes = {
  onError: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default QuestionSearchOptions;
