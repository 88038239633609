import React, { useReducer } from "react";
import SearchResult from "./SearchResult";
import { findQuestionsByDrugForm } from "../../api/questions";
import QuestionModal from "./QuestionModal";
import QuestionSearchOptions from "./QuestionSearchOptions";
import Loader from "../UI/Loader";
import ScreenHeader from "../common/ScreenHeader";

const INITIAL_STATE = {
  isLoading: false,
  barcode: null,
  drugForm: null,
  questions: null,
  showForm: false,
  error: null,
  questionToEdit: null,
};

const ACTIONS = {
  SET_LOADING: "SET_LOADING",
  SET_DATA: "SET_DATA",
  SET_ERROR: "SET_ERROR",
  ADD_QUESTION: "ADD_QUESTION",
  EDIT_QUESTION: "EDIT_QUESTION",
  DISMISS_MODAL: "DISMISS_MODAL",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return { ...INITIAL_STATE, isLoading: true };
    case ACTIONS.SET_DATA:
      return {
        ...INITIAL_STATE,
        barcode: action.barcode,
        drugForm: action.drugForm,
        questions: action.questions,
      };
    case ACTIONS.SET_ERROR:
      return {
        ...INITIAL_STATE,
        error: action.error,
      };
    case ACTIONS.ADD_QUESTION:
      return {
        ...state,
        showForm: true,
        questionToEdit: null,
      };
    case ACTIONS.EDIT_QUESTION:
      return {
        ...state,
        showForm: true,
        questionToEdit: action.question,
      };
    case ACTIONS.DISMISS_MODAL:
      return {
        ...state,
        showForm: false,
        questionToEdit: null,
      };
    default:
      return INITIAL_STATE;
  }
};

const QuestionScreen = (props) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const drugFormSelectionHandler = async (drugForm) => {
    dispatch({ type: ACTIONS.SET_LOADING });
    try {
      const questions = await findQuestionsByDrugForm(drugForm.id);
      dispatch({
        type: ACTIONS.SET_DATA,
        drugForm: drugForm,
        questions: questions,
      });
    } catch (error) {
      dispatch({ type: ACTIONS.SET_ERROR, error: error });
    }
  };

  const formDismissHandler = () => {
    dispatch({ type: ACTIONS.DISMISS_MODAL });
  };

  const editHandler = (id) => {
    const filteredQuestions = state.questions.filter((item) => item.id === id);
    if (filteredQuestions.length === 1) {
      dispatch({ type: ACTIONS.EDIT_QUESTION, question: filteredQuestions[0] });
    }
  };

  const renderResult = () => {
    return (
      <SearchResult
        items={state.questions}
        drugForm={state.drugForm}
        onAddClick={() => {
          dispatch({ type: ACTIONS.ADD_QUESTION });
        }}
        onEdit={editHandler}
      />
    );
  };

  const formSaveHandler = async (response) => {
    dispatch({ type: ACTIONS.DISMISS_MODAL });
    try {
      const questions = await findQuestionsByDrugForm(state.drugForm.id);
      dispatch({
        type: ACTIONS.SET_DATA,
        barcode: state.barcode,
        drugForm: state.drugForm,
        questions: questions,
      });
    } catch (error) {
      dispatch({ type: ACTIONS.SET_ERROR, error: error });
    }
  };

  const renderError = () => {
    if (!state.error) {
      return null;
    }

    let errorMessage = "Something went wrong";
    if (typeof state.error === "object" && state.error.message) {
      errorMessage = state.error.message;
    }

    return (
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  };

  let selectionInfo = null;

  if (state.drugForm) {
    selectionInfo = (
      <span>
        <b>Selection:</b> {state.drugForm.name}
      </span>
    );
  }

  return (
    <>
      <ScreenHeader title="Questions" />
      {renderError()}
      {state.drugForm && (
        <QuestionModal
          drugForm={state.drugForm.id}
          title={state.questionToEdit ? "Edit Question" : "Add Question"}
          show={state.showForm}
          question={state.questionToEdit}
          onDismiss={formDismissHandler}
          onSave={formSaveHandler}
          onError={(error) => console.log(error)}
        />
      )}
      <QuestionSearchOptions
        onError={(error) => {
          console.log(error);
        }}
        onSelect={drugFormSelectionHandler}
      >
        {selectionInfo}
      </QuestionSearchOptions>
      {state.isLoading && <Loader height="200px" />}
      {!state.isLoading && state.questions && (
        <div className="my-4">{renderResult()}</div>
      )}
    </>
  );
};

export default QuestionScreen;
