import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getDrug = async (id) => {
  const url = `${API_BASE_URL}/drugs/${id}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return response.data;
};
