import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FaChevronLeft } from "react-icons/fa";

import classes from "./ScreenHeader.module.css";

const ScreenHeader = (props) => {
  const history = useHistory();
  const { title, showBackButton } = props;

  const backButtonContent = (
    <div className="col flex-grow-0">
      <div
        className={[classes.backButton, "align-items-center"].join(" ")}
        onClick={history.goBack}
      >
        <FaChevronLeft className={classes.backIcon} size />
      </div>
    </div>
  );

  return (
    <>
      <div className={["row", classes.header].join(" ")}>
        {showBackButton && backButtonContent}
        <div className="col d-flex align-items-center">
          <h1 className="h5 mb-0">
            <b>{title}</b>
          </h1>
        </div>
      </div>
      <hr className="mt-0" />
    </>
  );
};

ScreenHeader.propTypes = {
  title: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool,
};

export default ScreenHeader;
